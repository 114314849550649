import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Dna } from 'react-loader-spinner';
import { BsUpload } from 'react-icons/bs';

import { PRO_API, fetchDogByName, updateDogById } from '../services';

import Header from '../components/header';

import {
	Toast,
	ErrorToast,
	editProfilePictureModal,
	pictureModal,
} from '../utils/modals';

import Swal from 'sweetalert2';
import { download, getNextImageFilename } from '../utils/helpers';
import { FEEDBACKMESSAGES } from '../utils/constants';
import { useStatus } from '../hooks/useStatus';
import { IFile, IMember } from '../interfaces';

export function DogPage() {
	const navigate = useNavigate();
	const location = useLocation();
	const name = location.pathname;

	const [busy, setBusy] = useState(true);
	const [data, setData] = useState<IMember>();
	const [images, setImages] = useState<IFile[]>();

	useStatus(name, navigate);

	useEffect(() => {
		const getDog = async () => {
			setBusy(true);

			await fetchDogByName(name.slice(1)).then((res) => {
				setData(res.data);
				setImages(res.data.images);
				setBusy(false);
			});
		};
		getDog();
	}, [name]);

	function uploadImage(data: IMember, file?: File, isProfile: boolean = false) {
		if (!file) return;
		setBusy(true);
		const fileName = getNextImageFilename(data, file.name);
		handleUpdate(data, 'IMAGE_UPLOAD', isProfile, file, fileName);
		setBusy(false);
	}

	const handleUpdate = (
		data: IMember,
		mode: string = 'UPDATE',
		isProfile: boolean = false,
		file?: File,
		fileName?: string
	) => {
		updateDogById(data, isProfile, file, fileName)
			.then((res) => {
				if (res.status === 200) {
					setData(res.data.updated);
					setImages(res.data.updated.images);
					Toast(FEEDBACKMESSAGES[mode].title);
				}
			})
			.catch(() => ErrorToast(FEEDBACKMESSAGES[mode].error));
	};

	return (
		<div className='mainView bg-white h-100'>
			<Header type={'dog'} routeName={name} />
			<Dna
				visible={busy}
				height='160'
				width='160'
				ariaLabel='dna-loading'
				wrapperClass='dna-spinner'
			/>
			{data && (
				<>
					<div className='container-cat-ig m-5 d-flex flex-column align-items-center'>
						<div
							className='image-dog shadow'
							onClick={() => {
								editProfilePictureModal(data).then((file) => {
									if (file) uploadImage(data, file, true);
								});
							}}>
							{data.profilePicture ? (
								<img
									className='img-fluid icofoto'
									src={`${PRO_API}/${data.profilePicture}`}
									alt=''
								/>
							) : (
								<span
									className='font-heraldry bold text-white'
									style={{ fontSize: '60px' }}>
									?
								</span>
							)}
						</div>
						<div className='mt-5 alias text-black'>
							{'També coneguda com a'}
							{data?.alsoKnownAs?.map((alias, index) => {
								return (
									<span
										key={index}
										className='bold normal alias ml-1 mr-1'>
										{alias}
									</span>
								);
							})}
						</div>
						<div className='mt-5 alias text-black'>
							{'Naixement: '}
							<span className='bold normal'>{data.birth}</span>
						</div>
						{data.death && (
							<div className='alias text-black'>
								{'Mort: '}
								<span className='bold normal'>{data.death}</span>
							</div>
						)}
					</div>
					<div className='title mt-3 text-black'>{`Col·lecció d'imatges`}</div>
					<div className='scrolling-wrapper top-border bg-white'></div>
					<div className='grid-wrapper'>
						<div className='card'>
							<div
								className='collection-image shadow'
								style={{ borderWidth: '2px' }}>
								<label
									htmlFor='file-upload'
									className='label-upload'>
									<BsUpload size={40} />
									<input
										id='file-upload'
										name='file-input'
										type={'file'}
										accept={'image/*'}
										style={{ display: 'none' }}
										onChange={({ target }) => {
											if (target && target.files?.length)
												uploadImage(data, target?.files[0]);
										}}
									/>
								</label>
							</div>
						</div>
						{images?.map((image, index) => {
							return (
								<div
									className='card'
									key={index}
									onClick={() =>
										pictureModal(image, data.name).then(
											(res) => {
												if (res.isDenied) {
													const params = {
														...data,
														profilePicture: image.file,
													};
													handleUpdate(
														params,
														'IMAGE_UPDATE'
													);
													//profile
												} else if (
													res.dismiss ===
													Swal.DismissReason.cancel
												) {
													download(
														image.file as unknown as string
													);
												}
											}
										)
									}>
									<div className='collection-image shadow'>
										<img
											className='img-fluid icofoto-collection'
											src={`${PRO_API}/${image.file}`}
											alt=''
										/>
									</div>
								</div>
							);
						})}
					</div>
				</>
			)}
		</div>
	);
}
