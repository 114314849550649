import axios from 'axios';

export const baseClient = () => {
	return axios.create();
};

export const authClient = () => {
	return axios.create({
		withCredentials: true,
	});
};

const PRO =
	process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'pro';

export const PRO_API = 'https://api.dynasty.rguixaro.dev';

export const SERVER_URL = PRO ? PRO_API : 'http://localhost:4001';

export const API = `${SERVER_URL}/api`;
