import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { IoClose, IoChevronBack } from 'react-icons/io5';
import { RiMenu4Fill } from 'react-icons/ri';

import { logout } from '../services';
import { useAuthActions } from '../store';

export default function Navbar() {
	const navigate = useNavigate();
	const location = useLocation();

	const routeName = location.pathname;
	const [menuOpen, setMenuOpen] = useState(false);
	const [navbar, showNavbar] = useState(false);

	const { clearSession } = useAuthActions();

	const closeMenu = () => setMenuOpen(false);

	useEffect(() => {
		showNavbar(
			routeName !== '/development' &&
				routeName !== '/maintenance' &&
				routeName !== '/auth' &&
				routeName !== '/'
		);
	}, [routeName]);

	return navbar ? (
		<nav>
			<div className='container-fluid d-flex justify-content-between position-relative'>
				{routeName !== '/home' && (
					<button className='top-left menu-button' onClick={() => navigate(-1)}>
						<div>
							<IoChevronBack color={'black'} />
						</div>
					</button>
				)}
				{!menuOpen && (
					<button
						className='top-right menu-button'
						onClick={() => setMenuOpen(true)}>
						<div>
							<RiMenu4Fill color={'black'} />
						</div>
					</button>
				)}
				{menuOpen && (
					<div className={`menu ${menuOpen ? ' active' : ''}`}>
						<div className='close position-absolute' onClick={closeMenu}>
							<IoClose color={'black'} />
						</div>
						<div
							className='row d-flex flex-wrap h-100 bg-white justify-content-center'
							style={{ alignContent: 'space-evenly' }}>
							<div className='row d-flex align-content-center flex-wrap'>
								<div className='col-10 offset-1 py-2'>
									<button
										className={`w-50 font-heraldry bold shadow ${
											routeName === '/home'
												? 'btnDefaultB bg-white text-black'
												: 'btnDefault bg-black text-white'
										}`}
										onClick={() => {
											closeMenu();
											navigate('/');
										}}>
										{'INICI'}
									</button>
								</div>
								<div className='col-10 offset-1 py-2'>
									<button
										className={`w-50 font-heraldry bold shadow ${
											routeName === '/dynasty'
												? 'btnDefaultB bg-white text-black'
												: 'btnDefault bg-black text-white'
										}`}
										onClick={() => {
											closeMenu();
											navigate('/dynasty');
										}}>
										{'DYNASTY'}
									</button>
								</div>
								<div className='col-10 offset-1 py-2'>
									<button
										className={`w-50 font-heraldry bold shadow ${
											routeName === '/schatz'
												? 'btnDefaultB bg-white text-black'
												: 'btnDefault bg-black text-white'
										}`}
										onClick={() => {
											closeMenu();
											navigate('/schatz');
										}}>
										{'SCHATZ'}
									</button>
								</div>
								<div className='col-10 offset-1 py-2'>
									<button
										className={`w-50 font-heraldry bold shadow ${
											routeName === '/duna'
												? 'btnDefaultB bg-white text-black'
												: 'btnDefault bg-black text-white'
										}`}
										onClick={() => {
											closeMenu();
											navigate('/duna');
										}}>
										{'DUNA'}
									</button>
								</div>
							</div>
							<div className='col-10 py-2'>
								<button
									className={
										'font-heraldry bold shadow btnDefaultB bg-white text-black'
									}
									onClick={() => {
										logout();
										clearSession();
									}}>
									{'SORTIR'}
								</button>
							</div>
						</div>
					</div>
				)}
			</div>
		</nav>
	) : (
		<></>
	);
}
