import { IMessage } from '../interfaces';

export const FEEDBACKMESSAGES: { [key: string]: IMessage } = {
	UPDATE: {
		title: 'DESCENDENT ACTUALITZAT!',
		text: 'Dades guardades amb èxit!',
		error: `No s'ha pogut actualitzar la informació.`,
	},
	IMAGE_UPLOAD: {
		title: 'PENJADA!',
		text: 'Image penjada amb èxit!',
		error: `No s'ha pogut penjar la imatge.`,
	},
	IMAGE_UPDATE: {
		title: 'ACTUALITZADA!',
		text: 'Image de perfil actualitzada amb èxit!',
		error: `No s'ha pogut actualitzar la imatge.`,
	},
	ADD: {
		title: 'DESCENDENT AFEGIT',
		text: 'Descendent creat amb èxit!',
		error: `No s'ha pogut afegit el nou descendent.`,
	},
	DEPORT: {
		title: 'Desterrar descendent',
		text: 'Descendent desterrat amb èxit!',
		error: `No s'ha pogut desterrar el descendent.`,
		buttonText: 'Desterrar',
	},
	REPATRIATE: {
		title: 'Repatriar descendent?',
		text: 'Descendent repatriat amb èxit!',
		error: `No s'ha pogut repatriar el descendent.`,
		buttonText: 'Repatriar',
		confirmButton: 'btnDefault bg-blue w-50',
	},
	EXILE: {
		title: 'Exiliar descendent?',
		text: 'Descendent exiliat amb èxit!',
		error: `No s'ha pogut exiliar el descendent.`,
		buttonText: 'Exiliar',
		confirmButton: 'btnDefault bg-blue w-50',
	},
	PARDON: {
		title: 'Indultar descendent?',
		text: 'Descendent indultat amb èxit!',
		error: `No s'ha pogut indultat el descendent.`,
		buttonText: 'Indultar',
		confirmButton: 'btnDefault bg-blue w-50',
	},
	DELETE: {
		title: 'DESCENDENT ELIMINAT!',
		text: `Enhorabona, acabes de destruir una part de la dinastia`,
		error: `T'has salvat, s'ha produït un error i no s'ha borrat l'existència de cap gat.`,
	},
	CONFIRM: {
		title: 'Compte!',
		text: `Si elimines aquest membre de la dinastia també eliminaràs els seus descendents (en cas que n'hi hagi)`,
		error: '',
	},
	CONFIRM_AGAIN: {
		title: `N'estàs segur?`,
		text: 'Tingues en compte que aquesta acció és irreversible. La dinastia està a les teves mans!',
		buttonText: 'Seguríssim',
		error: '',
	},
};

export const DEFAULT_LOCATION = 'Carrer Escultura 15, Castellgalí';
