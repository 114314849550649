import { create } from 'zustand';
import { useStoreWithEqualityFn } from 'zustand/traditional';
import { devtools } from 'zustand/middleware';

type AuthStore = {
	isAuthenticated: boolean;
	actions: {
		authenticate: () => void;
		clearSession: () => void;
	};
};

export const authStore = create<AuthStore>()(
	devtools(
		(set) => ({
			isAuthenticated: false,
			actions: {
				authenticate: () => set({ isAuthenticated: true }),
				clearSession: () => set({ isAuthenticated: false }),
			},
		}),
		{ name: 'auth-store' }
	)
);

/**
 * Required for zustand stores, as the lib doesn't expose this type
 */
type ExtractState<S> = S extends {
	getState: () => infer T;
}
	? T
	: never;

type Params<U> = Parameters<typeof useStoreWithEqualityFn<typeof authStore, U>>;

// Selectors
const authSelector = (state: ExtractState<typeof authStore>) =>
	state.isAuthenticated;
const actionsSelector = (state: ExtractState<typeof authStore>) =>
	state.actions;

// Getters
export const getAuthenticated = () => authSelector(authStore.getState());
export const getAuthActions = () => actionsSelector(authStore.getState());

function useAuthStore<U>(selector: Params<U>[1], equalityFn?: Params<U>[2]) {
	return useStoreWithEqualityFn(authStore, selector, equalityFn);
}

// Hooks
export const useIsAuthenticated = () => useAuthStore(authSelector);
export const useAuthActions = () => useAuthStore(actionsSelector);
