import React from 'react';
import { Dna } from 'react-loader-spinner';

export function Splash() {
	return (
		<div className='mainView bg-white h-100'>
			<div className='fadeAnim'>
				<Dna
					visible={true}
					height='160'
					width='160'
					ariaLabel='dna-loading'
					wrapperClass='dna-spinner'
				/>
			</div>
		</div>
	);
}
