import React, { useState, useEffect } from 'react';
import { AxiosResponse } from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { Dna } from 'react-loader-spinner';
import {
	IoMdFemale,
	IoMdMale,
	IoIosReturnRight,
	IoIosReturnLeft,
} from 'react-icons/io';
import { BiExit } from 'react-icons/bi';
import { BsUpload } from 'react-icons/bs';
import { AiOutlineStop } from 'react-icons/ai';
import Swal from 'sweetalert2';

import {
	PRO_API,
	addDescendant,
	updateDescendant,
	removeDescendant,
	fetchCatById,
} from '../services';
import Header from '../components/header';

import {
	confirmModal,
	confirmModalWithInput,
	editModal,
	editProfilePictureModal,
	ErrorToast,
	pictureModal,
	Toast,
} from '../utils/modals';
import { download, getNextImageFilename } from '../utils/helpers';
import { DEFAULT_LOCATION, FEEDBACKMESSAGES } from '../utils/constants';
import { IFile, IMember } from '../interfaces';
import { useDataActions } from '../store';

export function CatPage() {
	const navigate = useNavigate();
	const { id } = useParams();
	const { setLineage } = useDataActions();

	const [busy, setBusy] = useState(true);
	const [data, setData] = useState<IMember>();
	const [ancestor, setAncestor] = useState<IMember>();
	const [showDelete, setShowDelete] = useState(false);
	const [images, setImages] = useState<IFile[]>();

	useEffect(() => {
		const getCat = async () => {
			setBusy(true);
			if (!id) return;
			await fetchCatById(id)
				.then((res) => {
					const member = res.data;
					if (member.ancestor)
						fetchCatById(member.ancestor)
							.then((resAncestor) => {
								setAncestor(resAncestor.data);
							})
							.catch(() => navigate(-1));
					setData(member);
					setImages(member.images);
					setBusy(false);
				})
				.catch(() => navigate(-1));
		};
		getCat();
	}, [id, navigate]);

	const handleUpdate = (
		data: IMember,
		mode: string = 'UPDATE',
		isProfile: boolean = false,
		file?: File,
		fileName?: string
	) => {
		updateDescendant(data, isProfile, file, fileName)
			.then((res: AxiosResponse) => {
				if (res.status === 200) {
					setData(res.data.updated);
					setImages(res.data.updated.images);
					setLineage(res.data.lineage);
					Toast(FEEDBACKMESSAGES[mode].title);
				}
			})
			.catch(() => ErrorToast(FEEDBACKMESSAGES[mode].error));
	};

	function uploadImage(data: IMember, file?: File, isProfile: boolean = false) {
		if (!file) return;
		setBusy(true);
		const fileName = getNextImageFilename(data, file.name);
		handleUpdate(data, 'IMAGE_UPLOAD', isProfile, file, fileName);
		setBusy(false);
	}

	function handleEdit() {
		editModal(data).then((res) => {
			if (res.isConfirmed) {
				setBusy(true);
				const result: Partial<IMember> = {
					...data,
					name: res.value.name,
					alias: res.value.alias,
					gender: res.value.gender,
					birth: res.value.birth,
					death: res.value.death,
				};
				handleUpdate(result as IMember);
				setBusy(false);
			}
		});
	}

	function handleAdd() {
		editModal().then((res) => {
			if (res.isConfirmed && data) {
				const { value } = res;
				setBusy(true);
				addDescendant({
					name: value?.name,
					alias: value.alias,
					gender: value.gender,
					birth: value.birth,
					death: value.death,
					ancestor: data._id,
				})
					.then((res) => {
						if (res.status === 201) {
							setData(res.data.updated);
							setLineage(res.data.lineage);
							Toast(FEEDBACKMESSAGES.ADD.title);
						}
					})
					.catch(() => ErrorToast(FEEDBACKMESSAGES.ADD.error));

				setBusy(false);
			}
		});
	}

	async function handleExile(pardoned: boolean) {
		setBusy(true);
		if (pardoned) {
			confirmModal({
				icon: 'question',
				title: FEEDBACKMESSAGES.PARDON.title,
				confirmText: FEEDBACKMESSAGES.PARDON.buttonText || '',
				confirmButton: FEEDBACKMESSAGES.PARDON.confirmButton || '',
			}).then(async (res) => {
				if (res.isConfirmed) {
					if (!data) return;
					const params = {
						...data,
						exiled: false,
						lastKnownLocation: DEFAULT_LOCATION,
					};
					handleUpdate(params);
					Toast('Indultat');
				}
			});
		} else {
			await confirmModal({
				icon: 'question',
				title: FEEDBACKMESSAGES.EXILE.title,
				confirmText: FEEDBACKMESSAGES.EXILE.buttonText || '',
			}).then((res) => {
				if (res.isConfirmed && res.value) {
					if (!data) return;
					const params = {
						...data,
						exiled: true,
						lastKnownLocation: 'Exili',
					};
					handleUpdate(params);
					Toast('Exiliat');
				}
			});
		}
		setBusy(false);
	}

	async function handleDeport(repatriate: boolean) {
		setBusy(true);
		if (repatriate) {
			confirmModal({
				icon: 'question',
				title: FEEDBACKMESSAGES.REPATRIATE.title,
				confirmText: FEEDBACKMESSAGES.REPATRIATE.buttonText,
				confirmButton: FEEDBACKMESSAGES.REPATRIATE.confirmButton,
			}).then(async (res) => {
				if (res.isConfirmed) {
					if (!data) return;
					const params = {
						...data,
						deported: false,
						lastKnownLocation: DEFAULT_LOCATION,
					};
					handleUpdate(params);
					Toast('Repatriat');
				}
			});
		} else {
			await confirmModalWithInput({
				title: FEEDBACKMESSAGES.DEPORT.title,
				confirmText: FEEDBACKMESSAGES.DEPORT.buttonText || '',
			}).then((res) => {
				if (res.isConfirmed && res.value) {
					if (!data) return;
					const params = {
						...data,
						deported: true,
						lastKnownLocation: res.value,
					};
					handleUpdate(params);

					Toast('Desterrat');
				}
			});
		}
		setBusy(false);
	}

	function handleRemove() {
		confirmModal({
			title: FEEDBACKMESSAGES.CONFIRM.title,
			text: FEEDBACKMESSAGES.CONFIRM.text,
		}).then(async (res) => {
			if (res.isConfirmed) {
				confirmModal({
					title: FEEDBACKMESSAGES.CONFIRM_AGAIN.title,
					text: FEEDBACKMESSAGES.CONFIRM_AGAIN.text,
					confirmText: FEEDBACKMESSAGES.CONFIRM_AGAIN.buttonText,
				}).then(async (res) => {
					if (res.isConfirmed) {
						if (!data) return;
						setBusy(true);
						removeDescendant(data._id)
							.then((resdelete) => {
								if (resdelete.status === 204) {
									Toast(FEEDBACKMESSAGES.DELETE.title);
									navigate('/');
								}
							})
							.catch(() => ErrorToast(FEEDBACKMESSAGES.DELETE.error));
						setBusy(false);
					}
				});
			}
		});
	}

	return (
		<div className='mainView bg-white' style={{ paddingBottom: 0 }}>
			<Header routeName='cat' />
			<Dna
				visible={busy}
				height='160'
				width='160'
				ariaLabel='dna-loading'
				wrapperClass='dna-spinner'
			/>
			{data && (
				<>
					<div className='container-cat-ig d-flex flex-column align-items-center'>
						<div
							className='image-big shadow'
							onClick={() =>
								editProfilePictureModal(data).then((file) => {
									if (file) uploadImage(data, file, true);
								})
							}>
							{data.profilePicture ? (
								<img
									className='icofoto-big'
									src={`${PRO_API}/${data.profilePicture}`}
									alt=''
								/>
							) : (
								<span
									className='font-heraldry bold text-white'
									style={{ fontSize: '60px' }}>
									?
								</span>
							)}
							{ancestor && (
								<div className='ancestor-container'>
									<div className='image-small shadow'>
										<img
											className='img-fluid icofoto-small'
											src={`${PRO_API}/${ancestor.profilePicture}`}
											alt=''
										/>
									</div>
									<div
										className='name font-heraldry mt-2 text-center bold text-black'
										style={{ fontSize: 10 }}>
										{ancestor.name}
									</div>
								</div>
							)}
						</div>

						<div className='container-data'>
							<div className='nameBig font-heraldry mt-3 bold text-black'>
								{data.name}
							</div>
							<div className='d-flex align-items-center justify-content-center mt-3 mb-2'>
								{data.gender === 'Female' ? (
									<IoMdFemale size={20} />
								) : data.gender === 'Male' ? (
									<IoMdMale size={20} />
								) : (
									<></>
								)}
								<div className='alias pl-1'>{data.alias}</div>
							</div>
							<div className='location text-blue'>
								{data.lastKnownLocation}
							</div>
							<div className='alias text-black mt-3'>{`${data.birth}`}</div>
							{data.death && (
								<div className='alias text-black'>{`${data.death}`}</div>
							)}
						</div>
					</div>
					<button
						className='btnDefault bg-black swal2-styled'
						type='button'
						onClick={handleEdit}>
						{'Editar'}
					</button>
					<button
						className='btnDefault bg-black swal2-styled mt-2'
						type='button'
						onClick={handleAdd}>
						{'Afegir'}
					</button>
					{!data.death && !data.deported && !data.exiled && (
						<button
							className='btnDeport bg-red swal2-styled mt-3'
							type='button'
							onClick={() => handleDeport(false)}>
							<BiExit />
							{'Desterrar'}
							<BiExit />
						</button>
					)}
					{!data.death && !data.exiled && !data.deported && (
						<button
							className='btnDeport bg-red swal2-styled mt-3'
							type='button'
							onClick={() => handleExile(false)}>
							<AiOutlineStop />
							{'Exiliar'}
							<AiOutlineStop />
						</button>
					)}
					{!data.death && data.deported && (
						<button
							className='btnDeport bg-blue swal2-styled mt-3 mb-3'
							type='button'
							onClick={() => handleDeport(true)}>
							<IoIosReturnRight />
							{'REPATRIAR'}
							<IoIosReturnLeft />
						</button>
					)}
					{!data.death && data.exiled && (
						<button
							className='btnDeport bg-blue swal2-styled mt-3 mb-3'
							type='button'
							onClick={() => handleExile(true)}>
							<IoIosReturnRight />
							{'INDULTAR'}
							<IoIosReturnLeft />
						</button>
					)}
					<div className='bg-light mainView mt-5 top-border'>
						{data.children.length ? (
							<div className='w100'>
								<div className='title'>{'Descendents'}</div>
								<div className='scrolling-wrapper '>
									{data.children.map((child, index) => {
										return (
											<div
												className='card shadow'
												key={index}
												onClick={() =>
													navigate(`/cat/${child._id}`)
												}>
												<div className='name font-heraldry mb-1 pt-2 bold text-black'>
													{child.name}
												</div>
												<div className='image shadow'>
													{child.profilePicture ? (
														<img
															className='img-fluid icofoto'
															src={`${PRO_API}/${child.profilePicture}`}
															alt=''
														/>
													) : (
														<span
															className='font-heraldry bold text-black'
															style={{
																fontSize: '60px',
															}}>
															?
														</span>
													)}
												</div>
											</div>
										);
									})}
								</div>
							</div>
						) : null}
						<div className='top-border w-100' />
						<div className='title mt-3'>{`Col·lecció d'imatges`}</div>
						<div className='grid-wrapper'>
							<div className='card'>
								<div
									className='collection-image shadow'
									style={{ borderWidth: '2px' }}>
									<label
										htmlFor='file-upload'
										className='label-upload'>
										<BsUpload size={40} />
										<input
											id='file-upload'
											name='file-input'
											type={'file'}
											accept={'image/*'}
											style={{ display: 'none' }}
											onChange={({ target }) => {
												if (target && target.files?.length)
													uploadImage(
														data,
														target?.files[0]
													);
											}}
										/>
									</label>
								</div>
							</div>
							{images?.map((image, index) => {
								return (
									<div
										className='card'
										key={index}
										onClick={() =>
											pictureModal(image, data.name).then(
												(res) => {
													if (res.isDenied) {
														const profilePicture =
															image.file;
														const params = {
															...data,
															profilePicture,
														};
														handleUpdate(
															params,
															'IMAGE_UPDATE'
														);
													} else if (
														res.dismiss ===
														Swal.DismissReason.cancel
													)
														download(
															image.file as unknown as string
														);
												}
											)
										}>
										<div className='collection-image shadow'>
											<img
												className='img-fluid icofoto-collection'
												src={`${PRO_API}/${image.file}`}
												alt=''
											/>
										</div>
									</div>
								);
							})}
						</div>
						{showDelete ? (
							<div className='w-50 mt-2'>
								<div
									className='text-grey'
									onClick={() => setShowDelete(false)}>
									{'Menys opcions'}
								</div>
								<button
									className='btnDefault bg-red swal2-styled'
									type='button'
									onClick={handleRemove}>
									{'Eliminar'}
								</button>
							</div>
						) : (
							<div
								className='text-grey mt-2'
								onClick={() => setShowDelete(true)}>
								{'Més opcions'}
							</div>
						)}
					</div>
					{data.deported && (
						<div className='label absolute-label bg-red mt-2 shadow slideAnim'>
							{'DESTERRAT'}
						</div>
					)}
					{data.exiled && (
						<div className='label absolute-label bg-blue mt-2 shadow slideAnim'>
							{'EXILIAT'}
						</div>
					)}
				</>
			)}
		</div>
	);
}
