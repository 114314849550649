import React from 'react';
import { BrowserRouter } from 'react-router-dom';

import './App.css';
import './app/styles/style.css';

import { Routing } from './routes';
import Navbar from './app/components/navbar';

function App({ basename }: { basename: string | undefined }) {
	return (
		<BrowserRouter basename={basename}>
			<div className='App'>
				<Navbar />
				<Routing />
			</div>
		</BrowserRouter>
	);
}

export default App;
