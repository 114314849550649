import React, { useState } from 'react';

import Header from '../components/header';
import { login } from '../services';
import { getAuthActions } from '../store/auth-store';

export function Auth() {
	const { authenticate } = getAuthActions();

	const [checking, setChecking] = useState(false);
	const [password, setPassword] = useState('');
	const [auxText, setAuxText] = useState('Accedir');
	const [error, showError] = useState(false);

	function handleError() {
		showError(true);
		setPassword('');
		setAuxText('Error');
		setChecking(false);
	}

	function clearError() {
		showError(false);
		setAuxText('Accedir');
	}

	function checkLogin() {
		if (password) {
			setAuxText('Espera');
			setChecking(true);
			login(password)
				.then((response) => {
					if (response.status === 200) authenticate();
					else handleError();
				})
				.catch((error) => {
					console.log({ error });
					handleError();
				});
		} else handleError();
	}

	return (
		<div className='mainView pb-0 h-100'>
			<Header routeName='auth' />
			<div className='bg-blur' />
			<div className='auth-container shadow'>
				<div className='font-bold medium-large-text txt-black'>
					{'Benvingut a la DINASTIA'}
				</div>
				<input
					className={`mt-5 ${error && 'shadow-red'}`}
					type={'password'}
					value={password}
					placeholder={'Contrasenya'}
					onChange={(event) => {
						const text = event.target.value;
						setPassword(text);
						if (text.length) clearError();
					}}
				/>
				<div className='text-red mt-2' style={{ height: 40 }}>
					{error && 'Contrasenya incorrecta'}
				</div>
				<button
					className={`btnLogin shadow ${checking ? 'active' : ''}`}
					onClick={() => checkLogin()}>
					<div className='btnLoginTextContainer'>
						<div className='btnLoginText'>{auxText}</div>
						{checking && <div className='dot-elastic' />}
					</div>
				</button>
			</div>
		</div>
	);
}
