import Swal from 'sweetalert2';
import { PRO_API } from '../services';
import { input, selectInput, dateInput } from './utils';
import { IMember } from '../interfaces';
import 'animate.css';

export const alertError = ({
	error,
	customMessage,
}: {
	error: any;
	customMessage: string;
}) => {
	const message =
		error?.response?.data?.message ||
		error?.response?.statusText ||
		error ||
		customMessage ||
		'Se ha producido un error al realizar la operación';

	const params: { [key: string]: string | boolean | object } = {
		icon: 'error',
		title: (customMessage?.length < 30 && customMessage) || 'Error...',
		text: message,
		confirmButtonText: 'Tornar',
		allowEnterKey: false,
		customClass: {
			confirmButton: 'btnDefault bg-black w-100',
		},
	};

	return Swal.fire(params);
};

export const alertSuccess = ({
	title,
	customMessage,
}: {
	title: string;
	customMessage: string;
}) => {
	const message = customMessage || 'Operación completada con éxito!';

	const params: { [key: string]: string | boolean | object } = {
		icon: 'success',
		title,
		text: message,
		confirmButtonText: 'Tornar',
		allowEnterKey: false,
		customClass: {
			confirmButton: 'btnDefault bg-black w-100',
		},
	};

	return Swal.fire(params);
};

export const confirmModal = ({
	title,
	text = '',
	confirmText,
	confirmButton,
	icon,
}: {
	title: string;
	text?: string;
	confirmText?: string;
	confirmButton?: string;
	icon?: string;
}) => {
	const params: { [key: string]: string | boolean | object } = {
		icon: icon || 'warning',
		title,
		text,
		confirmButtonText: confirmText || 'Eliminar',
		denyButtonText: 'Cancel·lar',
		showDenyButton: true,
		showCloseButton: true,
		allowEnterKey: false,
		showLoaderOnConfirm: true,
		customClass: {
			confirmButton: confirmButton || 'btnDefault bg-red w-50',
			denyButton: 'btnDefault bg-black w-100',
		},
	};

	return Swal.fire(params);
};

export const confirmModalWithInput = ({
	title,
	text = '',
	confirmText,
}: {
	title: string;
	text?: string;
	confirmText: string;
}) => {
	const params: { [key: string]: string | boolean | object } = {
		title,
		text,
		confirmButtonText: confirmText,
		input: 'text',
		inputAttributes: {
			autocapitalize: 'off',
		},
		inputLabel: 'Introdueix la nova localització',
		inputPlaceholder: 'Ubicació',
		denyButtonText: 'Cancel·lar',
		showDenyButton: true,
		showCloseButton: true,
		allowEnterKey: false,
		customClass: {
			input: 'swal2-confirm btnUpload swal2-styled w-50',
			confirmButton: 'btnDefault bg-red w-50',
			denyButton: 'btnDefault bg-black w-100',
		},
		showClass: {
			popup: 'animate__animated animate__fadeIn animate__faster',
		},
		hideClass: {
			popup: 'animate__animated animate__fadeOut animate__faster',
		},
		inputValidator: (value: string) => {
			if (!value) {
				return `Has d'introduir una ubicació`;
			}
		},
	};

	return Swal.fire(params);
};

function initializeData(
	data: IMember | undefined,
	genderFemale: {
		checked: boolean;
		addEventListener: (arg0: string, arg1: () => void) => void;
	},
	genderMale: {
		checked: boolean;
		addEventListener: (arg0: string, arg1: () => void) => void;
	},
	genderUnknown: {
		checked: boolean;
		addEventListener: (arg0: string, arg1: () => void) => void;
	},
	birth: any,
	death: any
) {
	genderFemale.checked = data?.gender === 'Female';
	genderMale.checked = data?.gender === 'Male';
	genderUnknown.checked = data?.gender === 'Unknown';
	if (data?.birth) birth.valueAsDate = new Date(data.birth);
	if (data?.death) death.valueAsDate = new Date(data.death);

	genderFemale.addEventListener('click', function () {
		genderMale.checked = false;
		genderUnknown.checked = false;
	});
	genderMale.addEventListener('click', function () {
		genderFemale.checked = false;
		genderUnknown.checked = false;
	});
	genderUnknown.addEventListener('click', function () {
		genderMale.checked = false;
		genderFemale.checked = false;
	});
}

export const editModal = (data?: IMember) => {
	const html =
		input('Name', data?.name || '') +
		input('Alias', data?.alias || '') +
		selectInput('Gender') +
		dateInput('Birth') +
		dateInput('Death');
	const params = {
		title: data?.name || 'Nou membre',
		html: html,
		confirmButtonText: 'Confirmar',
		denyButtonText: 'Cancel·lar',
		showDenyButton: true,
		showCloseButton: true,
		allowEnterKey: false,
		showLoaderOnConfirm: true,
		showClass: {
			popup: 'animate__animated animate__fadeInLeft animate__faster',
		},
		hideClass: {
			popup: 'animate__animated animate__fadeOutRight animate__faster',
		},
		customClass: {
			title: 'nameBig font-heraldry text-black',
			actions: 'w-100',
			confirmButton: 'btnDefault bg-black w-50',
			denyButton: 'btnDefault bg-red w-35',
		},

		preConfirm: () => {
			const nameInput = document.getElementById('Name') as HTMLInputElement;
			const aliasInput = document.getElementById('Alias') as HTMLInputElement;
			const genderFemale = document.getElementById(
				'Gender-female'
			) as HTMLInputElement;
			const genderMale = document.getElementById(
				'Gender-male'
			) as HTMLInputElement;
			/* const genderUnknown = document.getElementById(
				'Gender-unknown'
			) as HTMLInputElement; */
			const birth = document.getElementById('Birth') as any;
			const death = document.getElementById('Death') as any;

			//check if right
			return {
				name: nameInput?.value,
				alias: aliasInput?.value,
				birth: birth?.value,
				gender: genderFemale?.checked
					? 'Female'
					: genderMale?.checked
					? 'Male'
					: 'Unknown',
				death: death?.value,
			};
		},
	};
	const res = Swal.fire(params);
	const genderFemale = document.getElementById(
		'Gender-female'
	) as HTMLInputElement;
	const genderMale = document.getElementById('Gender-male') as HTMLInputElement;
	const genderUnknown = document.getElementById(
		'Gender-unknown'
	) as HTMLInputElement;
	const birth = document.getElementById('Birth') as HTMLInputElement;
	const death = document.getElementById('Death') as HTMLInputElement;

	initializeData(data, genderFemale, genderMale, genderUnknown, birth, death);

	if (genderFemale) {
		genderFemale.addEventListener('click', function () {
			genderMale.checked = false;
			genderUnknown.checked = false;
		});
	}

	if (genderMale) {
		genderMale.addEventListener('click', function () {
			genderFemale.checked = false;
			genderUnknown.checked = false;
		});
	}

	if (genderUnknown) {
		genderUnknown.addEventListener('click', function () {
			genderMale.checked = false;
			genderFemale.checked = false;
		});
	}

	return res;
};

export const editProfilePictureModal = async (data: IMember) => {
	const { value: file } = await Swal.fire({
		title: data?.name,
		text: data.profilePicture
			? `Actualitzar`
			: `${
					(data.gender === 'Female'
						? 'La '
						: data.gender === 'Male'
						? 'El '
						: '') +
					data.name +
					' encara no té una imatge de perfil.'
			  }`,
		input: 'file',
		inputAttributes: {
			accept: 'image/*',
			'aria-label': 'Upload your profile picture',
			placeholder: 'ep',
		},
		imageUrl: data.profilePicture ? `${PRO_API}/${data.profilePicture}` : null,
		imageAlt: '',
		showCloseButton: true,
		showLoaderOnConfirm: true,
		focusConfirm: false,
		allowEnterKey: false,
		showClass: {
			popup: 'animate__animated animate__fadeInDown animate__faster',
		},
		hideClass: {
			popup: 'animate__animated animate__fadeOutDown animate__faster',
		},
		customClass: {
			image: 'modal-image shadow',
			title: 'modal-title w-100 nameBig font-heraldry text-black',
			confirmButton: 'btnDefault bg-black w-100',
			input: 'swal2-confirm btnUpload swal2-styled w-75',
			htmlContainer: data.profilePicture ? '' : 'mt-5 pt-5',
		},
	});
	return file;
};

const format = (date: Date) => {
	const stringDate = String(
		date.toLocaleDateString('ca', {
			weekday: 'long',
			year: 'numeric',
			month: 'long',
			day: 'numeric',
		})
	);
	return stringDate.charAt(0).toUpperCase() + stringDate.slice(1);
};

export const pictureModal = async (image: { date: Date; file: File }, name = {}) => {
	const params = {
		title: name,
		text: image.date ? format(new Date(image.date)) : '',
		imageUrl: `${PRO_API}/${image.file}`,
		imageAlt: '',
		showCloseButton: true,
		/* focusConfirm: true, */
		showCancelButton: true,
		denyButtonText: 'Foto de perfil',
		cancelButtonText: 'Descarregar',
		showDenyButton: true,
		reverseButtons: true,
		showLoaderOnConfirm: true,
		focusConfirm: false,
		showClass: {
			popup: 'animate__animated animate__fadeInUp animate__faster',
		},
		hideClass: {
			popup: 'animate__animated animate__fadeOutUp animate__faster',
		},
		customClass: {
			image: 'modal-image-big shadow',
			title: 'modal-title w-100 nameBig font-heraldry text-black',
			confirmButton: 'btnDefault bg-black w-100',
			/* cancelButton: 'btnDefault bg-black w-50', */
			denyButton: 'btnDefaultB bg-white w-50',
			cancelButton: 'btnDefault w-35',
			htmlContainer: 'html-text',
		},
	};
	return Swal.fire(params);
};

export const Toast = async (text: string) => {
	return Swal.mixin({
		toast: true,
		position: 'top-end',
		showConfirmButton: false,
		timer: 3000,
		timerProgressBar: true,
		didOpen: (toast) => {
			toast.addEventListener('mouseenter', Swal.stopTimer);
			toast.addEventListener('mouseleave', Swal.resumeTimer);
		},
	}).fire({
		icon: 'success',
		title: text || `T'has autoritzat!`,
	});
};

export const ErrorToast = async (text: string) => {
	return Swal.mixin({
		toast: true,
		position: 'top-end',
		showConfirmButton: false,
		timer: 3000,
		timerProgressBar: true,
		didOpen: (toast) => {
			toast.addEventListener('mouseenter', Swal.stopTimer);
			toast.addEventListener('mouseleave', Swal.resumeTimer);
		},
	}).fire({
		icon: 'error',
		title: text || `T'has autoritzat!`,
	});
};
