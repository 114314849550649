import { API, authClient } from './client';
import { IDog, IMember } from '../../interfaces';

/**
 *
 * Auth
 *
 */

export const login = async (password: string) => {
	return authClient().post(`${API}/admin/login`, { password: password });
};

export const logout = async () => {
	return authClient().get(`${API}/admin/logout`);
};

export const refreshTokens = () => {
	return authClient().get(`${API}/admin/refresh`);
};

export const getCredentials = () => {
	return authClient().get(`${API}/admin/credentials`);
};

/**
 *
 * Dynasty
 *
 */

export const fetchStatistics = async () => {
	return authClient().get(`${API}/member/stats`);
};

export const fetchLineage = async () => {
	return authClient().get(`${API}/member`);
};

export const fetchCatById = async (id: string) => {
	return authClient().get(`${API}/member/${id}`);
};

export const addDescendant = async (descendant: Partial<IMember>) => {
	return authClient().post(`${API}/member`, descendant);
};

export const updateDescendant = async (
	descendant: IMember,
	isProfile: boolean,
	file?: File,
	fileName?: string
) => {
	if (file && fileName) {
		const res = await uploadFile(file, 'cats', fileName);
		descendant.images.push({
			file: res.data.fileURL,
			date: new Date(file.lastModified),
		});
		if (isProfile) descendant.profilePicture = res.data.fileURL;
	}
	return authClient().put(`${API}/member/${descendant._id}`, descendant);
};

export const removeDescendant = async (id: string) => {
	return authClient().delete(`${API}/member/${id}`);
};

const uploadFile = async (file: File, folder: string, fileName: string) => {
	const formData = new FormData();
	formData.append('folder', folder); //amb barra o sense
	formData.append('file', file, fileName);
	return authClient().post(`${API}/file/single-file`, formData);
};

/**
 *
 * Dogs
 *
 */

export const fetchDogs = async () => {
	return authClient().get(`${API}/dog`);
};

export const fetchDogByName = async (name: string) => {
	return authClient().get(`${API}/dog/name/${name}`);
};

export const updateDogById = async (
	dog: IDog,
	isProfile: boolean,
	file?: File,
	fileName?: string
) => {
	if (file && fileName) {
		const res = await uploadFile(file, 'dogs', fileName);
		dog.images.push({
			file: res.data.fileURL,
			date: new Date(file.lastModified),
		});
		if (isProfile) dog.profilePicture = res.data.fileURL;
	}
	return authClient().put(`${API}/dog/${dog._id}`, dog);
};

/**
 *
 * Screens
 *
 */

export const getPageAvailability = async (page: string) => {
	return authClient().get(`${API}/screen/${page}`);
};
