import React from 'react';
import ReactDOM from 'react-dom/client';

import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const { PUBLIC_URL } = process.env;

const root = document.getElementById('root');
if (root) {
	const rootElement = ReactDOM.createRoot(root);
	rootElement.render(
		<React.StrictMode>
			<App basename={PUBLIC_URL} />
		</React.StrictMode>
	);
}

reportWebVitals();
