import { getPageAvailability } from '../services';
import { useEffect, useRef } from 'react';

const useStatus = (screenName: string, navigate: any) => {
	const statusRef = useRef<string>('');

	useEffect(() => {
		function handleRedirection() {
			if (statusRef.current === 'available') return;
			else
				navigate(`/${statusRef.current}`, {
					state: { previousScreen: screenName },
				});
		}
		getPageAvailability(screenName).then((res) => {
			if (res.status === 200) {
				statusRef.current = res.data.status;
				return handleRedirection();
			} else
				navigate('/development', { state: { previousScreen: screenName } });
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
};

export { useStatus };
