import React, {
	useRef,
	LegacyRef,
	useEffect,
	useState,
	useCallback,
} from 'react';
import { useNavigate } from 'react-router-dom';
import Tree from 'react-d3-tree';

import Header from '../components/header';
import containerCat from '../components/catContainer';

import { useStatus } from '../hooks/useStatus';
import { IMember, IObjectProps } from '../interfaces';
import { useCenteredTree } from '../utils';
import { useDataActions, useLineage } from '../store';
import { fetchLineage } from '../services';
import { Dna } from 'react-loader-spinner';

export function Dynasty() {
	const navigate = useNavigate();
	const ref = useRef(null);
	const { setLineage } = useDataActions();

	const lineage = useLineage();
	const [busy, setBusy] = useState(lineage ? false : true);

	const [translate, containerRef] = useCenteredTree();

	const refTree = useRef<Tree>(null);

	useStatus('/dynasty', navigate);

	const nodeSize = { x: 250, y: 200 };
	const foreignObjectProps: IObjectProps = {
		width: nodeSize.x,
		height: nodeSize.y - 85,
		x: -60,
	};

	const fetchData = useCallback(async () => {
		try {
			setBusy(true);
			const data = (await fetchLineage())?.data;
			setLineage(data);

			setBusy(false);
		} catch (error) {
			console.log(error);
			setBusy(false);
		}
	}, []);

	useEffect(() => {
		if (!lineage) fetchData();
	}, [fetchData]);

	function onClick(data: IMember) {
		navigate(`/cat/${data._id}`);
	}

	async function handleExport() {
		/* const canvas = await html2canvas(ref.current);
		const image = canvas.toDataURL('image/png', 1.0);
		const link = window.document.createElement('a');
		link.style = 'display:none;';
		link.download = 'test.png';
		link.href = image;
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
		link.remove(); */
	}

	return (
		<div className='mainViewNoScroll bg-white'>
			<Header routeName='dynasty' />
			<Dna
				visible={busy}
				height='160'
				width='160'
				ariaLabel='dna-loading'
				wrapperClass='dna-spinner'
			/>
			{lineage && (
				<div ref={ref} className='h100'>
					<div
						className='h100 w100'
						ref={containerRef as LegacyRef<HTMLDivElement>}>
						<Tree
							data={lineage}
							nodeSize={nodeSize}
							translate={translate}
							renderCustomNodeElement={(rd3tProps) =>
								containerCat({ ...rd3tProps, foreignObjectProps, onClick })
							}
							dimensions={{
								width: (translate && translate?.x * 2) || 1000,
								height: (translate && translate?.y * 16) || 1000,
							}}
							orientation={'vertical'}
							pathFunc={'elbow'}
							enableLegacyTransitions={true}
							centeringTransitionDuration={1000}
							draggable={true}
							transitionDuration={1000}
							hasInteractiveNodes={false}
							scaleExtent={{ min: 0.5, max: 1 }}
							onNodeMouseOut={(nodeData, evt) => {
								console.log('onNodeMouseOut', nodeData, evt);
							}}
							svgClassName='svg'
							ref={refTree as LegacyRef<Tree>}
						/>
					</div>
				</div>
			)}
			<div
				className='label absolute-label bottom bg-black mt-2 shadow slideAnim'
				onClick={handleExport}>
				{'EXPORTAR'}
			</div>
		</div>
	);
}
