import { useCallback, useState } from 'react';
import { Point } from 'react-d3-tree';

import { authClient, PRO_API } from '../services';
import { IMember, IStats } from '../interfaces';

export const useCenteredTree = () => {
	const [translate, setTranslate] = useState<Point>({ x: 0, y: 0 });
	const containerRef = useCallback((containerElem: any) => {
		if (containerElem !== null) {
			const { width, height } = containerElem.getBoundingClientRect();
			setTranslate({ x: width / 2, y: height / 16 });
		}
	}, []);
	return [translate, containerRef] as [
		Point | undefined,
		(containerElem: any) => void
	];
};

async function toDataURL(url: string) {
	const response = await authClient().get(url, { responseType: 'blob' });
	return URL.createObjectURL(response.data);
}

export const download = async (fileName: string) => {
	const element = document.createElement('a');
	element.style.display = 'none';
	element.href = await toDataURL(`${PRO_API}/${fileName}`);
	element.download = fileName.split('/')[2];
	element.click();
};

export const deepCopy = (obj: object) => {
	return JSON.parse(JSON.stringify(obj));
};

export const getIndex = (array: any[]) => {
	if (array && array.length) {
		const lastImage = array[array?.length - 1];
		return parseInt(lastImage.file?.split('-')[2].split('.')[0]) + 1;
	}
	return '1';
};

export const getNextImageFilename = (data: IMember, fileName: string) => {
	return `${String(data.name).toLowerCase()}-${getIndex(
		data.images
	)}${fileName.substring(fileName.indexOf('.'))}`;
};

export function extractStatistics(data: any) {
	const stats: IStats = {
		totalNumber: 0,
		aliveNumber: 0,
		exiledNumber: 0,
		deportedNumber: 0,
		maleNumber: 0,
		femaleNumber: 0,
		unknown: 0,
	};
	data.forEach((member: IMember) => {
		stats.totalNumber++;
		if (!member.death) stats.aliveNumber++;
		if (member.exiled) stats.exiledNumber++;
		if (member.deported) stats.deportedNumber++;
	});

	const alive = data.filter((member: IMember) => !member.death);
	stats.maleNumber = alive.filter(
		(member: IMember) => member.gender === 'Male'
	)?.length;
	stats.femaleNumber = alive.filter(
		(member: IMember) => member.gender === 'Female'
	)?.length;
	stats.unknown = alive.length - stats.maleNumber - stats.femaleNumber;
	alive.sort((a: IMember, b: IMember) => a.birth > b.birth);
	stats.oldest = alive[0];
	if (!stats.oldest) return stats;
	const oldStats = getAge(stats.oldest.birth);
	stats.oldest.age = oldStats.age;
	stats.oldest.time = oldStats.time;
	stats.youngest = alive[alive.length - 1];
	if (!stats.youngest) return stats;
	const youngStats = getAge(stats.youngest.birth);
	stats.youngest.age = youngStats.age;
	stats.youngest.time = youngStats.time;
	return stats;
}

function getAge(birth: string) {
	const date = new Date(birth);
	const years = new Date().getFullYear() - date.getFullYear();
	if (!years) {
		return { age: new Date().getMonth() - date.getMonth(), time: 'MESOS' };
	}
	return { age: years, time: 'ANYS' };
}
