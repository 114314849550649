import React from 'react';
import { useNavigate } from 'react-router-dom';

export default function Header({
	type = 'cat',
	routeName,
}: {
	type?: string;
	routeName: string;
}) {
	const navigate = useNavigate();
	return type === 'dog' ? (
		<div className='mt-5' onClick={() => navigate(routeName)}>
			<h1 className={`text-black font-heraldry`}>{routeName.slice(1)}</h1>
		</div>
	) : (
		<div className='mt-5 header' onClick={() => navigate('/')}>
			<h1 className={`text-black font-heraldry`}>{`Lia's`}</h1>
			<h1 className={`text-black font-heraldry`}>{`Dynasty`}</h1>
		</div>
	);
}
