import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Dna } from 'react-loader-spinner';

import { fetchLineage, fetchStatistics } from '../services';
import Header from '../components/header';
import { useStatus } from '../hooks/useStatus';
import { useDataActions, useStatistics } from '../store';
import { extractStatistics } from '../utils';

export function Home() {
	const navigate = useNavigate();
	const { setLineage, setStatistics } = useDataActions();
	const statistics = useStatistics();

	const [busy, setBusy] = useState(statistics ? false : true);

	useStatus('/dynasty', navigate);

	const fetchData = useCallback(async () => {
		try {
			setBusy(true);
			const data = (await fetchLineage())?.data;
			setLineage(data);
			const stats = (await fetchStatistics())?.data;
			setStatistics(extractStatistics(stats));
			setBusy(false);
		} catch (error) {
			console.log(error);
			setBusy(false);
		}
	}, []);

	useEffect(() => {
		if (!statistics) fetchData();
	}, [fetchData]);

	useEffect(() => {
		if (!busy) {
			const timer = setTimeout(() => {
				document.getElementById('first-card')?.classList.add('active');
			}, 500);
			return () => clearTimeout(timer);
		}
	}, [busy]);

	function handleScroll() {
		const cards = document.querySelectorAll('.reveal');
		const windowHeight = window.innerHeight;
		const elementVisible = 100;
		for (let i = 0; i < cards.length; i++) {
			const elementTop = cards[i].getBoundingClientRect().top;
			if (elementTop < windowHeight - elementVisible) {
				cards[i].classList.add('active');
			} else {
				cards[i].classList.remove('active');
			}
		}
	}

	return (
		<div
			className='mainView bg-white pb-0'
			style={{ height: '100%' }}
			id='page'
			onScroll={handleScroll}>
			<Header routeName='home' />
			<Dna
				visible={busy}
				height='160'
				width='160'
				ariaLabel='dna-loading'
				wrapperClass='dna-spinner'
			/>
			{statistics && (
				<div>
					<div className='stats-wrapper' id='wrapper'>
						<div id='first-card' className='card text-black pl-2 pr-2 reveal'>
							<div className='subcard'>
								<div className='text'>{'La dinastia està formada per '}</div>
								<div className='number pt-2'>
									{statistics?.totalNumber}
									<span className='number pl-2'>{'MEMBRES'}</span>
								</div>
							</div>
						</div>
						<div className='card bg-black text-white reveal'>
							<div className='subcard'>
								<div className='text'>
									<span className='bold'>{'ACTUALMENT'}</span>
									{' però, només'}
								</div>
								<div className='d-flex pt-2'>
									<div className='number'>{statistics?.aliveNumber}</div>
									<div className='text pl-2'>
										{' estan '}
										<span className='number'>{'VIUS'}</span>
									</div>
								</div>
							</div>
						</div>
						<div className='card text-black reveal'>
							<div className='subcard'>
								<div className='text'>{'Al llarg dels anys '}</div>
								<div className='d-flex'>
									<div className='number'>{statistics?.exiledNumber}</div>
									<div className='text pl-2'>{`MEMBRES s'han `}</div>
								</div>
								<div className='number pt-2'>{`EXILIAT`}</div>
							</div>
						</div>
						<div className='card bg-black text-white reveal'>
							<div className='subcard'>
								<div className='d-flex'>
									<div className='number'>{statistics?.deportedNumber}</div>
									<div className='text pl-2'>{` d'ells han estat `}</div>
								</div>
								<div className='number pt-2'>{' DESTERRATS'}</div>
							</div>
						</div>
						<div className='card text-black reveal'>
							<div className='subcard'>
								<div className='text'>
									{'El més '}
									<span className='number'>{'VELL'}</span>
									<div className='d-flex pt-4'>
										<div className='text'>{`${
											statistics.oldest?.gender === 'Male' ? 'El' : 'La'
										}`}</div>
										<div className='number pl-2'>{`${statistics.oldest?.name}`}</div>
									</div>
									<div className='text pl-2'>{' amb '}</div>
									<div className='number pl-2 d-flex justify-content-center'>
										{statistics.oldest?.age}
										<div className='number pl-2'>{statistics.oldest?.time}</div>
									</div>
								</div>
							</div>
						</div>
						<div className='card bg-black text-white reveal'>
							<div className='subcard'>
								<div className='text'>
									{`A dia d'`}
									<span className='number'>{'AVUI'}</span>
									{' hi ha '}
									<div className='d-flex justify-content-between'>
										<div className='number'>
											{statistics.femaleNumber}
											<div className='number'>{'GATES'}</div>
										</div>
										<div className='number'>
											{statistics.femaleNumber}
											<div className='number pl-2'>{'GATS'}</div>
										</div>
									</div>
									<div className='text pl-2'>{' i '}</div>
									<div className='number pl-2 d-flex justify-content-center'>
										{`${statistics.unknown}`}
										<div className='text pl-2'>{' SENSE '}</div>
									</div>
									<div className='number'>{'CONFIRMAR'}</div>
								</div>
							</div>
						</div>
						<div className='card text-black reveal'>
							<div className='subcard'>
								<div className='text'>
									{'El més '}
									<span className='number'>{'JOVE'}</span>
									<div className='d-flex pt-4'>
										<div className='text'>{`${
											statistics.youngest?.gender === 'Male' ? 'El' : 'La'
										}`}</div>
										<div className='number pl-2'>{`${statistics.youngest?.name}`}</div>
										<div className='text pl-2'>{' amb '}</div>
									</div>
									<div className='number pl-2 d-flex justify-content-center'>
										{statistics.youngest?.age}
										<div className='number pl-2'>
											{statistics.youngest?.time}
										</div>
									</div>
								</div>
							</div>
						</div>
						<button
							className='w-100 font-bold bold bg-black text-white reveal'
							style={{ fontSize: 'xx-large', letterSpacing: '10px' }}
							onClick={() => navigate('/dynasty')}>
							{`L'ARBRE`}
						</button>
					</div>
				</div>
			)}
		</div>
	);
}
