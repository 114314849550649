import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

export function Menu() {
	const navigate = useNavigate();
	const location = useLocation();
	const routeName = location.pathname;

	return (
		<nav>
			<div className='container-fluid d-flex justify-content-between position-relative'>
				<div className='menu  active'>
					<div className='row d-flex align-content-center flex-wrap h-100 bg-white'>
						<div className='col-10 offset-1 py-2'>
							<button
								className={` w-50 font-heraldry bold ${
									routeName === '/home'
										? 'btnDefaultB bg-white text-black'
										: 'btnDefault bg-black text-white'
								}`}
								onClick={() => {
									navigate('/home');
								}}>
								{'INICI'}
							</button>
						</div>
						<div className='col-10 offset-1 py-2'>
							<button
								className={` w-50 font-heraldry bold ${
									routeName === '/dynasty'
										? 'btnDefaultB bg-white text-black'
										: 'btnDefault bg-black text-white'
								}`}
								onClick={() => {
									navigate('/dynasty');
								}}>
								{'DYNASTY'}
							</button>
						</div>
						<div className='col-10 offset-1 py-2'>
							<button
								className={`w-50 font-heraldry bold ${
									routeName === '/schatz'
										? 'btnDefaultB bg-white text-black'
										: 'btnDefault bg-black text-white'
								}`}
								onClick={() => {
									navigate('/schatz');
								}}>
								{'SCHATZ'}
							</button>
						</div>
						<div className='col-10 offset-1 py-2'>
							<button
								className={`w-50 font-heraldry bold ${
									routeName === '/duna'
										? 'btnDefaultB bg-white text-black'
										: 'btnDefault bg-black text-white'
								}`}
								onClick={() => {
									navigate('/duna');
								}}>
								{'DUNA'}
							</button>
						</div>
					</div>
				</div>
			</div>
		</nav>
	);
}
