import { create } from 'zustand';
import { useStoreWithEqualityFn } from 'zustand/traditional';
import { IMember, IStats } from '../interfaces';

interface DataStore {
	lineage: IMember | null;
	statistics: IStats | null;
	actions: {
		setLineage: (lineage: IMember) => void;
		setStatistics: (statistics: IStats) => void;
	};
}

export const dataStore = create<DataStore>()((set) => ({
	lineage: null,
	statistics: null,
	actions: {
		setLineage: (lineage: IMember) => set({ lineage: lineage }),
		setStatistics: (statistics: IStats) => set({ statistics: statistics }),
	},
}));

/**
 * Required for zustand stores, as the lib doesn't expose this type
 */
type ExtractState<S> = S extends {
	getState: () => infer T;
}
	? T
	: never;

type Params<U> = Parameters<typeof useStoreWithEqualityFn<typeof dataStore, U>>;

// Selectors
const lineageSelector = (state: ExtractState<typeof dataStore>) =>
	state.lineage;
const statisticsSelector = (state: ExtractState<typeof dataStore>) =>
	state.statistics;
const actionsSelector = (state: ExtractState<typeof dataStore>) =>
	state.actions;

// Getters
export const getLineage = () => lineageSelector(dataStore.getState());
export const getStatistics = () => statisticsSelector(dataStore.getState());
export const getDataActions = () => actionsSelector(dataStore.getState());

function useAuthStore<U>(selector: Params<U>[1], equalityFn?: Params<U>[2]) {
	return useStoreWithEqualityFn(dataStore, selector, equalityFn);
}

// Hooks
export const useLineage = () => useAuthStore(lineageSelector);
export const useStatistics = () => useAuthStore(statisticsSelector);
export const useDataActions = () => useAuthStore(actionsSelector);
