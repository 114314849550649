import React from 'react';
import { IoMdFemale, IoMdMale } from 'react-icons/io';

import { PRO_API } from '../services';
import { IMember, IObjectProps } from '../interfaces';

const containerCat = ({
	nodeDatum,
	toggleNode,
	foreignObjectProps,
	onClick,
}: {
	nodeDatum: any;
	toggleNode: () => void;
	foreignObjectProps: IObjectProps;
	onClick: (data: IMember) => void;
}) => {
	async function handleClick(event: React.MouseEvent<HTMLDivElement>) {
		event.preventDefault();
		if (event.currentTarget.id === 'toggler') toggleNode();
		else onClick(nodeDatum);
	}

	return (
		<foreignObject {...foreignObjectProps} style={{ overflow: 'visible' }}>
			<div className='container-cat d-flex flex-row' onClick={handleClick}>
				<div
					className={`image shadow ${
						nodeDatum.exiled ? 'label-exiled' : ''
					} ${nodeDatum.deported ? 'label-deported' : ''}`}>
					{nodeDatum.profilePicture ? (
						<img
							className='img-fluid icofoto shadow'
							src={`${PRO_API}/${nodeDatum.profilePicture}`}
							alt=''
						/>
					) : (
						<span
							className='font-heraldry bold text-black'
							style={{ fontSize: '60px' }}>
							?
						</span>
					)}
					{nodeDatum.death && <div className='icofoto greyed' />}
				</div>
				<div className='container-data'>
					<div className='name font-heraldry text-black'>
						{nodeDatum.name}
					</div>
					{nodeDatum.gender && (
						<div className='gender shadow'>
							{nodeDatum.gender === 'Female' ? (
								<IoMdFemale size={15} />
							) : (
								<IoMdMale size={15} />
							)}
						</div>
					)}
					<div className='d-flex align-items-center justify-content-center mb-2'>
						<div className='alias'>{`${nodeDatum.alias}`}</div>
					</div>
					<div className='alias font-heraldry'>{`${nodeDatum.birth}`}</div>
					{nodeDatum.death && (
						<div className='alias font-heraldry'>{`${nodeDatum.death}`}</div>
					)}
					{/* 	{nodeDatum.children.length ? (
							<div className='visibility' id='toggler' onClick={handleClick}>
								{nodeDatum.__rd3t.collapsed ? 'Mostra' : 'Amaga'}
							</div>
						) : (
							<></>
						)} */}
				</div>
			</div>
		</foreignObject>
	);
};

export default containerCat;
