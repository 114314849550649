import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

export function DevelopmentPage() {
	const navigate = useNavigate();
	const location = useLocation();
	const previousScreen = location?.state?.previousScreen;

	const handleNavigate = () => {
		if (previousScreen === '/dynasty') navigate('/menu');
		else navigate('/');
	};

	return (
		<div className='centeredView d-flex flex-column align-items-center justify-content-center'>
			<div className='bold-font large-text'>{'EN CONSTRUCCIÓ'}</div>
			<div className='medium-text font-bold'>
				{'Aquesta secció estarà disponible proximament'}
			</div>
			<img
				className='mt-2 construction-gif'
				src={require('../../assets/gif/under_development.gif')}
				alt='loading...'
			/>
			<button
				className='btnDefault w-50 bg-black mt-5 font-bold'
				onClick={handleNavigate}>
				{'TORNAR'}
			</button>
		</div>
	);
}
