import React, { useState, useEffect } from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';

import { login, refreshTokens, getCredentials, logout } from './app/services';
import {
	Home,
	Auth,
	Dynasty,
	CatPage,
	DogPage,
	MaintenancePage,
	DevelopmentPage,
	Menu,
	Splash,
} from './app/pages';
import { getAuthActions, useIsAuthenticated } from './app/store';

export function Routing() {
	const [busy, setBusy] = useState(true);
	const { authenticate, clearSession } = getAuthActions();
	const isAuthenticated = useIsAuthenticated();

	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);
	const token = searchParams.get('token');

	useEffect(() => {
		if (!isAuthenticated && token)
			login(token)
				.then((response) => {
					if (response.status === 200) authenticate();
					setBusy(false);
				})
				.catch(() => {});
		else setBusy(false);
	}, [token]);

	// Checking wether we have credentials in cookies before going to login
	useEffect(() => {
		function fetchMyCredentials() {
			setBusy(true);
			setTimeout(async () => {
				try {
					const response = await getCredentials();
					if (response) authenticate();
					setBusy(false);
				} catch (error) {
					console.log({ error });
					await logout();
					clearSession();
				}
				setBusy(false);
			}, 1000);
		}
		fetchMyCredentials();
	}, []);

	// Refresh interval every 15 minutes if logged
	useEffect(() => {
		if (isAuthenticated) {
			const interval = setInterval(async () => {
				try {
					await refreshTokens();
				} catch (error) {
					console.log({ error, customMessage: 'Cannot refresh tokens.' });
					await logout();
					clearSession();
				}
			}, 900000);
			return () => clearInterval(interval);
		}
	}, [isAuthenticated]);

	return busy ? (
		<Routes>
			<Route path='' element={<Splash />} />
		</Routes>
	) : !isAuthenticated ? (
		<Routes>
			<Route path='*' element={<Navigate to='/auth' />} />
			<Route path='/auth' element={<Auth />} />
		</Routes>
	) : (
		<Routes>
			<Route path='*' element={<Navigate to='/home' />} />
			<Route path='/' element={<Navigate to='/home' />} />
			<Route path='/menu' element={<Menu />} />
			<Route path='/home' element={<Home />} />
			<Route path='/dynasty' element={<Dynasty />} />
			<Route path='/cat/:id' element={<CatPage />} />
			<Route path='/schatz' element={<DogPage />} />
			<Route path='/duna' element={<DogPage />} />
			<Route path='/development' element={<DevelopmentPage />} />
			<Route path='/maintenance' element={<MaintenancePage />} />
		</Routes>
	);
}
