const translateCatalan: { [key: string]: string } = {
	Name: 'Nom',
	Alias: 'Àlies',
	Birth: 'Naixement',
	Death: 'Mort',
	Female: 'Gata',
	Male: 'Gat',
	Unknown: 'Desconegut',
	Image: 'Imatge',
};

export const input = (label: string, value: string) => {
	return `<div class="d-flex w-100 align-items-center space-evenly mt-3 mb-3">
                    <div class="label">
                        ${translateCatalan[label]}
                    </div>
                    <input id=${label} class="swal2-input btnWidth" value="${
		value || ''
	}">
                </div>`;
};

export const dateInput = (label: string) => {
	return `<div class="d-flex w-100 align-items-center space-evenly mt-3 mb-3">
                    <div class="label">
                        ${translateCatalan[label]}
                    </div>
                    <input id=${label} class="swal2-input btnWidth" type="date">
                </div>`;
};

export const selectInput = (label: string) => {
	return `<div class="swal2-radio d-flex m-0 mt-3 mb-3">
                    <label class="m-0">
                        <input type="radio" id="${label}-male" name="status" value="Male">
                        <span class="swal2-label">${translateCatalan['Male']}</span>
                    </label>
                    <label class="m-0">
                        <input type="radio" id="${label}-female" name="status" value="Female">
                        <span class="swal2-label">${translateCatalan['Female']}</span>
                    </label>
                    <label class="m-0">
                        <input type="radio" id="${label}-unknown" name="status" value="Unknown">
                        <span class="swal2-label">${translateCatalan['Unknown']}</span>
                    </label>
                </div>`;
};

export const imageInput = (label: string) => {
	return `<div class="d-flex w-100 align-items-center space-evenly mt-3 mb-3">
                <div class="label">
                    ${translateCatalan[label]}
                </div>
                <input id="Image" type="file" accept="image/*" style="display:none;">                
                <input id="Image2" type="button" value="Seleccionar" class="swal2-confirm btnUpload swal2-styled" onclick="document.getElementById('Image').click();">
            </div>`;
};
